import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";

const search = instantsearch({
  indexName: "evikespecials",
  searchClient: instantMeiliSearch(
    "https://search.webenclave.com",
    "c214d2f1b2e3dc7bb3d39b8e2b84e26b77cf95b8a6808f1d235e7fbc89784e66",
    {
      limitPerRequest: 10
    }
  )
});

search.addWidgets([
  instantsearch.widgets.searchBox({
    container: "#searchbox"
  }),
  instantsearch.widgets.configure({
    hitsPerPage: 4,
    snippetEllipsisText: "...",
  }),
  instantsearch.widgets.hits({
    container: "#hits",
    templates: {
      item: `
        <div>
          <div class="hit-name">
            {{#helpers.highlight}}{ "attribute": "text" }{{/helpers.highlight}}
          </div>
          <a href="{{url}}" target="_blank" rel="noopener noreferrer"><img src="{{image}}" align="left" /></a>
          <div class="hit-description">
            {{#helpers.snippet}}{ "attribute": "discount" }{{/helpers.snippet}}
          </div>
          <div class="hit-info">Price: {{price}}</div>
          <div class="hit-info">Last on sale: {{createdAt}}</div>
        </div>
      `
    }
  }),
  instantsearch.widgets.pagination({
    container: "#pagination"
  })
]);

search.start();
